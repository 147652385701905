import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Markdown from 'react-markdown'
import { GatsbyImage } from 'gatsby-plugin-image'

import Hero from '../components/Hero'
import Seo from '../components/Seo'


const WriterTemplate = ({ data }) => {
  const writer = data.strapiWriter

  return (
    <Layout>
      <Seo title={writer.name}/>
      <Hero title={writer.name}/>
      <div className="section">
        <div className="container">
          <h1 className="title">{writer.name}</h1>
          <div className="content">

            <div className="columns">
              {writer.picture &&
                <div className="column is-half">
                  <GatsbyImage
                    image={writer.picture.localFile.childImageSharp.gatsbyImageData}
                    alt={writer.name}
                    imgStyle={{ borderRadius: "50%" }}
                  />
                </div>
              }
              <div className="column">
                <Markdown
                  children={writer.bio}
                  // remarkPlugins={[gfm]}
                  // rawSourcePos={sourcePosition:{end: {line: 3}}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WriterTemplate

export const query = graphql `
  query WriterTemplateQuery($id: String!) {
    strapiWriter(id: {eq: $id}) {
      strapiId
      name
      email
      bio
      picture {
        alternativeText
        localFile{
          childImageSharp {
            gatsbyImageData(width: 680, height: 680)
          }
        }
      }
    }
  }
`
